import Tile from './tileV2_doc';
import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';
import ProgressLoader from '../components/progress_loader';

import tutorialVideo from '../images/videos/My Doc Page Updated Bg.mp4';
import videoThumbnail from '../images/videos/thumbnail.png';

import PdfUpload from './dropZoneUplod';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            view_type: 'all',
            response: [],
            docList: [],
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            docView: 'all',
            pdfData: false,
            pdfFilename: false,
            pdfSize: false,
            doc_title: '',
            event: '',
            uploading_status: false,
            total_used_file_size: 0,
            progress_loader: false,
            sortDropdown: false,
            uploadDocModal: false,
            tutorialVideo: true,
            doNotShowChecked: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    skipVideoTutorial = () => {
        this.setState({
            tutorialVideo: false,
        })
        if (this.state.doNotShowChecked) {
            localStorage.setItem('tutorialVideo_uplodedDocuments', true);
        }
    }

    componentDidMount() {
        const storedData = localStorage.getItem('tutorialVideo_uplodedDocuments');
        if (storedData) {
            this.setState({ tutorialVideo: false });
        }

        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'uploaded_document_list', {
            params: {
                email: this.props.email,
            },
        }).then(res => {
            if (res.data.length > 0) {
                this.setState({
                    response: res.data.sort((a, b) => b.itime - a.itime),
                    docList: res.data.sort((a, b) => b.itime - a.itime),
                    total_used_file_size: res.data.reduce((sum, item) => sum + item.file_size, 0),
                })
            }
        });

        const videoElement = document.querySelector('.tutorial-video-modal video');
        if (videoElement) {
            videoElement.addEventListener('play', this.handleVideoPlay);
        }
    }

    componentWillUnmount() {
        const videoElement = document.querySelector('.tutorial-video-modal video');
        if (videoElement) {
            videoElement.removeEventListener('play', this.handleVideoPlay);
        }
    }

    handleVideoPlay = () => {
        //Delay timer for skip tutorial
        setTimeout(() => {
            const skipBtn = document.querySelector('.skip-btn');
            if (skipBtn) {
                skipBtn.style.display = 'flex';
                setTimeout(() => {
                    skipBtn.style.opacity = '1';
                }, 50);
            }
        }, 5000);
    }

    // checkEligibility = () => {
    //     if (this.props.user_type !== "organisation") {
    //         this.setState({
    //             warning: {
    //                 message: "Sorry, You need an organisation account to issue certificates.",
    //                 type: "warning",
    //                 learnmore: "create",
    //                 autoclose: false,
    //             }
    //         })
    //     } else {
    //         window.location = "/issue";
    //     }
    // }

    toggleDocView = (type) => {
        if (type === 'all') {
            this.setState({
                docList: this.state.response,
                docView: type,
            })
        } else {
            this.setState({
                docList: this.state.response.filter(item => item.view_type === type),
                docView: type,
            })
        }
    }

    handlePdfUpload = (e) => {
        var file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            this.setState({
                pdfData: file,
                pdfFilename: file.name,
                pdfSize: ((file.size) / 1000000).toPrecision(3) + " mb",
            })
        } else {
            this.setState({
                warning: {
                    message: "Invalid file format. Only PDF supported",
                    type: "warning",
                    learnmore: false,
                    autoclose: true,
                },
            })
        }
    }

    handleDropPdfUpload = (e) => {
        var file = e[0];
        this.setState({
            pdfData: file,
            pdfFilename: file.name,
            pdfSize: ((file.size) / 1000000).toPrecision(3) + " mb",
            uploadDocModal: true
        })
    }

    removePdfUpload = (e) => {
        this.setState({
            pdfData: false,
            pdfFilename: false,
            pdfSize: false,
        })
    }

    updateEvent = (e) => {
        this.setState({
            event: e.target.value.slice(0, 128)
        })
    }

    updateTitle = (e) => {
        this.setState({
            doc_title: e.target.value.slice(0, 128)
        })
    }

    handleServerUpload = (e) => {
        e.preventDefault();

        if (this.state.total_used_file_size < this.props.storage * 1024) {
            this.setState({
                uploading_status: true,
                progress_loader: true,
            });

            var imageTimestamp = Date.now();

            const fileSizeInBytes = this.state.pdfData.size;
            const maxSizeInBytes = 10 * 1024 * 1024; // Maximum file size in bytes (e.g., 10 MB)

            if (fileSizeInBytes < maxSizeInBytes) {
                const formData = new FormData();
                this.state.pdfData && (formData.append('pdfFile', this.state.pdfData, imageTimestamp + '.pdf'));
                formData.append('pdf', this.state.pdfData ? imageTimestamp + '.pdf' : null);
                formData.append('email', this.props.email);
                formData.append('doc_title', this.state.doc_title);
                formData.append('event', this.state.event);
                formData.append('file_size', parseInt((fileSizeInBytes / 1024) < 1 ? 1 : (fileSizeInBytes / 1024)));

                if (this.state.pdfData && this.state.doc_title !== '' && this.state.event !== '') {
                    axios.post(this.props.node_server_axios + 'user_upload_document', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                        }
                    }).then(res => {
                        this.setState({
                            response: res.data.sort((a, b) => b.itime - a.itime),
                            docList: res.data.sort((a, b) => b.itime - a.itime),
                            docView: 'all',
                            pdfData: false,
                            pdfFilename: false,
                            pdfSize: false,
                            doc_title: '',
                            event: '',
                            warning: {
                                message: "Document Uploaded.",
                                type: "success",
                                learnmore: false,
                                autoclose: true,
                            },
                            uploading_status: false,
                            progress_loader: false,
                            total_used_file_size: res.data.reduce((sum, item) => sum + item.file_size, 0),
                            uploadDocModal: false,
                        })
                    });
                } else {
                    this.setState({
                        warning: {
                            message: "Sorry, Upload form is incomplete.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        uploading_status: false,
                        progress_loader: false,
                    })
                }
            } else if (fileSizeInBytes === undefined) {
                this.setState({
                    warning: {
                        message: "Please upload a file.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    uploading_status: false,
                    progress_loader: false,
                })
            } else {
                this.setState({
                    warning: {
                        message: "Sorry, Only files under 10mb can be uploaded.",
                        type: "warning",
                        learnmore: false,
                        autoclose: true,
                    },
                    uploading_status: false,
                    progress_loader: false,
                })
            }
        } else {
            this.setState({
                warning: {
                    message: "Sorry, Document upload limit reached. Buy Storage to upload more.",
                    type: "warning",
                    learnmore: "buy",
                    autoclose: false,
                },
                uploading_status: false,
                progress_loader: false,
            })
        }
    }

    toggleSortDropdown = () => {
        this.setState({
            sortDropdown: !this.state.sortDropdown,
        })
    }

    sortBy = (value) => {
        switch (value) {
            case 'date-asc':
                this.setState({
                    docList: this.state.response.sort((a, b) => b.itime - a.itime),
                    sortDropdown: false,
                })
                break;
            case 'name-asc':
                this.setState({
                    docList: this.state.response.sort((a, b) => a.title.localeCompare(b.title)),
                    sortDropdown: false,
                })
                break;
            case 'date-desc':
                this.setState({
                    docList: this.state.response.sort((a, b) => a.itime - b.itime),
                    sortDropdown: false,
                })
                break;
            case 'name-desc':
                this.setState({
                    docList: this.state.response.sort((a, b) => b.title.localeCompare(a.title)),
                    sortDropdown: false,
                })
                break;
            default:
                this.setState({
                    docList: this.state.response.sort((a, b) => b.itime - a.itime),
                    sortDropdown: false,
                })
        }
    }

    toggleUploadDocumentModal = (e) => {
        e.preventDefault();
        this.setState({
            uploadDocModal: !this.state.uploadDocModal,
        });
    }

    doNotShowAgain = (e) => {
        this.setState({
            doNotShowChecked: e.target.checked,
        })
    }

    render() {
        const storage_used_percent = parseInt((this.state.total_used_file_size / (this.props.storage * 1024)) * 100);
        return (
            <React.Fragment>
                {this.state.tutorialVideo && (
                    <div className='tutorial-video-modal'>
                        <video src={tutorialVideo} muted={false} onEnded={this.skipVideoTutorial} controls poster={videoThumbnail} />
                        <div className='skip-btn'>
                            <input type='checkbox' onChange={this.doNotShowAgain} checked={this.state.doNotShowChecked} />
                            <p>Do not show this again</p>
                            <button onClick={this.skipVideoTutorial}>Skip <i className="fa-solid fa-forward"></i></button>
                        </div>
                    </div>
                )}
                {this.state.uploadDocModal && (
                    <div className='element-modal upload-document-modal'>
                        <div className='content'>
                            <button className='close-btn' onClick={this.toggleUploadDocumentModal}><i className="fa-solid fa-x"></i></button>
                            <div className='view-option public'>
                                <div className='view'>Upload your document</div>
                            </div>
                            <input type='text' placeholder='Document Title' value={this.state.doc_title} onChange={this.updateTitle} />
                            <div className='flex'>
                                <input type='text' placeholder='Event / Occasion' value={this.state.event} onChange={this.updateEvent} />
                                {!this.state.pdfSize ?
                                    <input type='file' className='custom-file-input' accept=".pdf" onChange={this.handlePdfUpload} />
                                    :
                                    <div className='chosen-doc'>
                                        <p>{this.state.pdfFilename}</p>
                                        <button onClick={this.removePdfUpload}><i className="fa-solid fa-x"></i></button>
                                    </div>
                                }
                            </div>
                            <div className='bottom'>
                                {this.state.uploading_status ?
                                    <p className='uploading-status'>Uploading...</p>
                                    :
                                    <a href='foo' className='upload-doc-btn' onClick={this.handleServerUpload} >Upload Document</a>
                                }
                            </div>
                        </div>
                    </div>
                )}

                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>User Uploaded<label>All Documents</label></h1>
                                <div className='toggle'>
                                    <button className={this.state.docView === 'all' ? 'active' : ''} onClick={() => { this.toggleDocView('all') }}><i>All</i><p>Certificates</p></button>
                                    <button className={this.state.docView === 'public' ? 'active' : ''} onClick={() => { this.toggleDocView('public') }}><p>Public</p><i className="fa-solid fa-lock-open"></i></button>
                                    <button className={this.state.docView === 'private' ? 'active' : ''} onClick={() => { this.toggleDocView('private') }}><p>Private</p><i className="fa-solid fa-lock"></i></button>
                                </div>
                            </div>
                            {/* <button className='btn-issue' onClick={this.checkEligibility}><i className="fa-solid fa-stamp"></i> Create Certificate</button> */}
                        </div>
                        <div className='document-header'>
                            {storage_used_percent > 70 ?
                                storage_used_percent > 90 ?
                                    <div className='popup-storagewarn above90'>
                                        Your Storage is almost full. You might not be able to upload new documents. Upgrade your plan for more storage and other benefits.
                                        <a href='/buy'>Upgrade</a>
                                    </div>
                                    :
                                    <div className='popup-storagewarn above70'>
                                        Your storage is nearing completion. Upgrade your plan for more storage and other benefits.
                                        <a href='/buy'>Upgrade</a>
                                    </div>
                                :
                                <div className='popup-storagewarn'>
                                    <p><span>{(this.state.total_used_file_size / 1024).toFixed(1)}</span> / {this.props.storage} mb used</p>
                                    <div className='storage-progress'>
                                        <div className='bar' style={{ width: storage_used_percent + "%" }}></div>
                                    </div>
                                </div>
                            }
                            <div className='sort-filter' onClick={this.toggleSortDropdown}>
                                {this.state.sortDropdown ? <i className="fa-regular fa-circle-xmark"></i> : <i className="fa-solid fa-arrow-down-wide-short"></i>}
                            </div>
                            {this.state.sortDropdown && (
                                <div className='dropdown-filter'>
                                    <p onClick={() => this.sortBy('date-asc')}>Newest <i className="fa-solid fa-arrow-up-long"></i></p>
                                    <p onClick={() => this.sortBy('date-desc')}>Oldest <i className="fa-solid fa-arrow-down-long"></i></p>
                                    <p onClick={() => this.sortBy('name-asc')}>Sort A to Z <i className="fa-solid fa-arrow-up-a-z"></i></p>
                                    <p onClick={() => this.sortBy('name-desc')}>Sort Z to A <i className="fa-solid fa-arrow-up-z-a"></i></p>
                                </div>
                            )}
                        </div>
                        <div className='tiles'>
                            {/* <div className='tileV2 video'>
                                <video src={tutorialVideo} muted autoPlay loop />
                            </div> */}
                            {this.state.docList && this.state.docList.length > 0 && (
                                <div className='tileV2 video'>
                                    <div className='upload-zone'>
                                        <PdfUpload onPdfUpload={this.handleDropPdfUpload} />
                                    </div>
                                </div>
                            )}

                            {this.state.docList && this.state.docList.length > 0 ? (
                                this.state.docList.map(tiles => {
                                    return (
                                        <Tile key={tiles.document_id} data={tiles} />
                                    )
                                })
                            ) :
                                <div className='upload-zone'>
                                    <PdfUpload onPdfUpload={this.handleDropPdfUpload} />
                                </div>
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                    <div className='fadeout'></div>
                </div>
                {
                    this.state.progress_loader && (<ProgressLoader />)
                }
            </React.Fragment>
        );
    }
}

export default TileComponent;